<template>
    <div>
           <!-- 面包屑区域 -->
          <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>
          
           <!-- 卡片视图区域 -->
          <el-card shadow="hover">
                 <Title :title="taskDetailsData.tasktype"></Title>
                 <div class="speed_of_progress">
                      <div class="item">
                          <div class="steps" :class="{steps_active:taskDetailsData.paystate==2||taskDetailsData.paystate==1||taskDetailsData.paystate==3||taskDetailsData.paystate==5}">提交任务，待付款</div>
                          <div class="triangle" :class="{triangle_active:taskDetailsData.paystate==2||taskDetailsData.paystate==1||taskDetailsData.paystate==3||taskDetailsData.paystate==5}"></div>
                      </div>

                       <div class="item">
                          <div class="steps" :class="{steps_active:taskDetailsData.paystate==1||taskDetailsData.paystate==3||taskDetailsData.paystate==5}">已付款，等待审核</div>
                          <div class="triangle" :class="{triangle_active:taskDetailsData.paystate==1||taskDetailsData.paystate==3||taskDetailsData.paystate==5}"></div>
                      </div>

                       <div class="item">
                          <div class="steps" :class="{steps_active:taskDetailsData.paystate==3||taskDetailsData.paystate==5}">审核通过，进行中</div>
                          <div class="triangle" :class="{triangle_active:taskDetailsData.paystate==3||taskDetailsData.paystate==5}"></div>
                      </div>

                       <div class="item">
                          <div class="steps" :class="{steps_active:taskDetailsData.paystate==5}">任务全部完成</div>
                      </div>
                 </div>
                 <div class="order_details">任务生成订单总数：{{taskDetailsData.allnum}} 单 &nbsp;&nbsp;已接单：{{taskDetailsData.haveorder}} 单 &nbsp;&nbsp;待确认：{{taskDetailsData.waitorder}} 单 &nbsp;&nbsp;已完成：{{taskDetailsData.isorder}} 单</div>

                  <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="任务详情" name="1">
                         <div class="external_box">
                                 <Title :title="tit[0]"></Title>
                                 <div class="floor">                                
                                     <div class="main">
                                          <div class="list">
                                             <div class="item">
                                               <div class="title">任务类型：</div>
                                               <div class="right font_color">{{taskDetailsData.tasktype}}</div>
                                             </div>
                                          </div>
                                          
                                          <div class="list">
                                              <div class="item">
                                                <div class="title">店铺名称：</div>
                                                <div class="right font_color">{{taskDetailsData.shopsname}}</div>
                                              </div>

                                              <div class="item">
                                                <div class="title">旺旺名称：</div>
                                                <div class="right">{{taskDetailsData.wangwang!=''?taskDetailsData.wangwang:'--'}}</div>
                                              </div>
                                          </div>

                                          <div class="list">
                                              <div class="item">
                                                <div class="title">商品主图：</div>
                                                <div class="right">
                                                    <img :src="taskDetailsData.goodsimg" style="width:120px;height:120px">
                                                </div>
                                              </div>

                                              <div class="item">
                                                <div class="title">名称：</div>
                                                <div class="right font_color">{{taskDetailsData.goodsname}}</div>
                                              </div>

                                              <div class="item">
                                                <div class="title">URL：</div>
                                                <div class="right font_color">{{taskDetailsData.goodsurl}}</div>
                                              </div>
                                          </div>

                                          <div class="list">
                                              <div class="item">
                                                <div class="title">显示价：</div>
                                                <div class="right font_color">{{taskDetailsData.showprice}}</div>
                                              </div>

                                              <div class="item">
                                                <div class="title">下单价：</div>
                                                <div class="right font_color">{{taskDetailsData.goodsprice}}</div>
                                              </div>

                                              <div class="item">
                                                <div class="title">规格/尺寸：</div>
                                                <div class="right font_color">{{taskDetailsData.goodsspecs}}</div>
                                              </div>

                                              <div class="item">
                                                <div class="title">下单数量：</div>
                                                <div class="right font_color">{{taskDetailsData.goodsnum}}个/件</div>
                                              </div>
                                          </div>

                                          <div class="list">
                                              <div class="item">
                                                <div class="title">任务平台：</div>
                                                <div class="right font_color">{{taskDetailsData.tasktype}}</div>
                                              </div>

                                              <!-- <div class="item">
                                                <div class="title">任务分类：</div>
                                                <div class="right font_color">垫付任务（手机拼多多任务）</div>
                                              </div> -->
                                          </div>

                                          <div class="list">
                                              <div class="item">
                                                <div class="title">商品成交价：</div>
                                                <div class="right font_color">{{taskDetailsData.goodsprice}}</div>
                                              </div>

                                              <div class="item">
                                                <div class="title">任务本金：</div>
                                                <div class="right font_color">{{taskDetailsData.goodsprice}}</div>
                                              </div>
                                          </div>

                                          <div class="list">
                                              <div class="item">
                                                <div class="title">服务费：</div>
                                                <div class="right font_color">{{taskDetailsData.xiaofei}}</div>
                                              </div>

                                              <div class="item">
                                                <div class="title">是否包邮：</div>
                                                <div class="right font_color">{{taskDetailsData.screen}}</div>
                                              </div>
                                          </div>

                                          <div class="list">
                                              <div class="item">
                                                <div class="title">是否使用优惠卷：</div>
                                                <div class="right font_color">{{taskDetailsData.ifcoupon}}</div>
                                              </div>

                                              <div class="item">
                                                <div class="title">优惠卷URL：</div>
                                                <div class="right font_color">{{taskDetailsData.couponurl}}</div>
                                              </div>

                                               <div class="item">
                                                <div class="title">优惠价：</div>
                                                <div class="right font_color">{{taskDetailsData.couponmoney}}</div>
                                              </div>
                                          </div>

                                          <div class="list">
                                              <div class="item">
                                                <div class="title">商品所在城市：</div>
                                                <div class="right font_color">{{taskDetailsData.goodslocation}}</div>
                                              </div>
                                          </div>

                                          <div class="list">
                                              <div class="item">
                                                <div class="title">订单留言：</div>
                                                <div class="right font_color">{{taskDetailsData.remarks}}</div>
                                              </div>
                                          </div>

                                         <div class="list">
                                              <div class="item">
                                                <div class="title">任务总数：</div>
                                                <div class="right font_color">{{taskDetailsData.allnum}}</div>
                                              </div>

                                               <div class="item">
                                                <div class="title">任务类型：</div>
                                                <div class="right font_color">{{taskDetailsData.typetxt}}</div>
                                              </div>
                                         </div>

                                         <!-- <div class="list">
                                              <div class="item">
                                                <div class="title">货比链接数量：</div>
                                                <div class="right font_color">0</div>
                                              </div>
                                         </div> -->
                                          
                                     </div>
                                 </div>

                                 <Title :title="taskDetailsData.typetxt+':（'+taskDetailsData.searchtypetxt+'）'"></Title>
                                 <div class="floor">
                                       <div class="main">
                                              <div class="s_list">
                                                  <div v-for="item in taskDetailsData.taskinfo" :key="item.id" class="list_item">
                                                         <div class="item">
                                                              <div class="title">搜索关键词：</div>
                                                              <div class="right font_color">{{item.searchValue}}</div>
                                                         </div>

                                                         <div class="item">
                                                              <div class="title">任务单数：</div>
                                                              <div class="right font_color">{{item.num}}</div>
                                                         </div>
                                                  </div>
                                              </div>
                                       </div>
                                 </div>

                                 <Title :title="tit[1]"></Title>
                                 <div class="floor">
                                       <div class="main">
                                              <div class="rules_box">    
                                                   <div class="list" v-for="item in taskDetailsData.planarr" :key="item.id">
                                                         <div class="item">开始放单时间：<span class="font_color">{{item.timeValue}}</span></div>           
                                                         <div class="item">每隔：<span class="font_color">{{item.minute}}</span>分钟</div>           
                                                         <div class="item">放出：<span class="font_color">{{item.num}}</span>单</div>           
                                                         <div class="item">总计：<span class="font_color">{{item.allnum}}</span>单</div>     
                                                    </div>                                     
                                              </div>

                                              <!-- <div class="list">                                              
                                                  <div class="item">
                                                    <div class="title">有效时间：</div>
                                                    <div class="right font_color">07:00:00 - 23:00:00</div>
                                                  </div>
                                              </div> -->
                                       </div>
                                 </div>

                                 <Title :title="tit[2]"></Title>
                                 <div class="floor">
                                       <div class="main">
                                              <div class="list">
                                                  <div class="item">
                                                    <div class="title">是否关注店铺：</div>
                                                    <div class="right font_color">{{taskDetailsData.dianpu}}</div>
                                                  </div>
                                              </div>

                                               <!-- <div class="list">
                                                  <div class="item">
                                                    <div class="title">手淘问大家：</div>
                                                    <div class="right font_color">否</div>
                                                  </div>
                                              </div> -->

                                               <div class="list">
                                                  <div class="item">
                                                    <div class="title">需要客服聊天：</div>
                                                    <div class="right font_color">{{taskDetailsData.kefu}}</div>
                                                  </div>
                                              </div>

                                               <div class="list">
                                                  <div class="item">
                                                    <div class="title">指定搜索关键词截图：</div>
                                                    <div class="right font_color">{{taskDetailsData.keysearch}}</div>
                                                  </div>
                                              </div>

                                               <div class="list">
                                                  <div class="item">
                                                    <div class="title">额外收藏宝贝：</div>
                                                    <div class="right font_color">{{taskDetailsData.baobei}}</div>
                                                  </div>
                                              </div>

                                               <!-- <div class="list">
                                                  <div class="item">
                                                    <div class="title">宝贝加购物车：</div>
                                                    <div class="right font_color">否</div>
                                                  </div>
                                              </div> -->

                                               <div class="list">
                                                  <div class="item">
                                                    <div class="title">指定货比关键词：</div>
                                                    <div class="right font_color">{{taskDetailsData.huobi}}</div>
                                                  </div>
                                              </div>

                                               <div class="list">
                                                  <div class="item">
                                                    <div class="title">指定竞品货比：</div>
                                                    <div class="right font_color">{{taskDetailsData.jingbi}}</div>
                                                  </div>
                                              </div>

                                               <div class="list">
                                                  <div class="item">
                                                    <div class="title">随机浏览三个货比商品：</div>
                                                    <div class="right font_color">{{taskDetailsData.liulan3}}</div>
                                                  </div>
                                              </div>

                                               <div class="list">
                                                  <div class="item">
                                                    <div class="title">地域限制：</div>
                                                    <div class="right font_color">{{taskDetailsData.address}}</div>
                                                  </div>
                                              </div>

                                               <div class="list">
                                                  <div class="item">
                                                    <div class="title">年龄限制：</div>
                                                    <div class="right font_color">{{taskDetailsData.age}}</div>
                                                  </div>

                                                   <div class="item">
                                                    <div class="title">性别限制：</div>
                                                    <div class="right font_color">{{taskDetailsData.sex}}</div>
                                                  </div>

                                                   <div class="item">
                                                    <div class="title">买家职业要求：</div>
                                                    <div class="right font_color">{{taskDetailsData.occupation}}</div>
                                                  </div>

                                                   <div class="item">
                                                    <div class="title">买家类目要求：</div>
                                                    <div class="right font_color">{{taskDetailsData.category}}</div>
                                                  </div>
                                              </div>

                                              
                                       </div>
                                 </div>

                                 <Title :title="tit[3]"></Title>
                                 <div class="floor">
                                      <div class="main">
                                             <div class="rules_box">    
                                                  <div class="list">
                                                        <div class="title">商家备注内容：</div>
                                                        <div class="right">
                                                            <div class="remarks_img_box" v-if="taskDetailsData.liuyan">
                                                                 <img v-for="item in taskDetailsData.liuyan.img" :key="item.id" :src="item.value" style="width:100px;height:100px;margin:5px"/>                                                            
                                                            </div>
                                                            <p class="font_color" v-if="taskDetailsData.liuyan">{{taskDetailsData.liuyan.txt}}</p>
                                                        </div>  
                                                   </div>                                     
                                             </div>
                                      </div>
                                 </div>
                         </div>
                    </el-tab-pane>


                    <el-tab-pane label="费用详情" name="2">
                         <div class="external_box">
                              <Title :title="tit[0]"></Title>                    
                               <!-- 列表区域  border加边框 stripe隔行变色 -->
                              <el-table :data="taskDetailsData.expenseDetails" border stripe :header-cell-style="{textAlign: 'center'}"  :cell-style="{textAlign: 'center'}" >
                                   <el-table-column label="分类" prop="type"></el-table-column>
                                   <el-table-column label="费用明细" prop="details"></el-table-column>
                                   <el-table-column label="数量" prop="num"></el-table-column>
                                   <el-table-column label="单价" prop="unitPrice"></el-table-column>
                                   <el-table-column label="小计" prop="subtotal"></el-table-column>
                              </el-table>
                              <div class="money_box">合计金额：本金<span class="money">{{taskDetailsData.allmoney}}</span>元</div>
                         </div>
                    </el-tab-pane>


                    <el-tab-pane label="进行中订单" name="3">
                          <div class="external_box">
                              <Title :title="tit[0]"></Title>
                              <p class="tips">用户未按照设置本金下单的，系统将不再自动过审，需商家手动审核，请及时去审核，以免时间过长导致用户申请退款,如超过48小时未审核，转入平台客服手动审核</p>
                               <!-- 列表区域  border加边框 stripe隔行变色 -->
                              <el-table :data="taskDetailsData.info" border stripe :header-cell-style="{textAlign: 'center'}"  :cell-style="{textAlign: 'center'}" >
                                   <el-table-column label="任务编号" prop="id"></el-table-column>
                                   <el-table-column label="接单时间" prop="ctime"></el-table-column>
                                   <el-table-column label="订单编号" prop="taskbh"></el-table-column>
                                   <el-table-column label="交易平台订单" prop="orderid"></el-table-column>
                                   <el-table-column label="接单账号" prop="userid"></el-table-column>
                                   <el-table-column label="实付金额/元" prop="money"> </el-table-column>
                                   <el-table-column label="订单状态" prop="statename"></el-table-column>
                              </el-table>

                              <!-- 分页区域 -->
                              <el-pagination
                                :current-page="queryInfo.pagenum"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :page-sizes="taskDetailsData.page_arr"
                                :page-size="queryInfo.pagesize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="taskDetailsData.num"
                              ></el-pagination>
                          </div>
                    </el-tab-pane>

                    <el-tab-pane label="已完成订单" name="4">
                          <div class="external_box">
                              <Title :title="tit[0]"></Title>
                               <!-- 列表区域  border加边框 stripe隔行变色 -->
                              <el-table :data="taskDetailsData.info" border stripe :header-cell-style="{textAlign: 'center'}"  :cell-style="{textAlign: 'center'}" >
                                   <el-table-column label="任务编号" prop="id"></el-table-column>
                                   <el-table-column label="接单时间" prop="ctime"></el-table-column>
                                   <el-table-column label="订单编号" prop="taskbh"></el-table-column>
                                   <el-table-column label="买手编号" prop="userid"></el-table-column>
                                   <el-table-column label="接单平台账号" prop="useraccount"></el-table-column>
                                   <el-table-column label="实付金额/元" prop="money"> </el-table-column>
                                   <el-table-column label="订单状态" prop="statename"></el-table-column>
                              </el-table>

                              <!-- 分页区域 -->
                              <el-pagination
                                :current-page="queryInfo.pagenum"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :page-sizes="taskDetailsData.page_arr"
                                :page-size="queryInfo.pagesize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="taskDetailsData.num"
                              ></el-pagination>
                          </div>
                    </el-tab-pane>


                    <el-tab-pane label="已驳回订单" name="5">
                          <div class="external_box">
                              <Title :title="tit[0]"></Title>
                               <!-- 列表区域  border加边框 stripe隔行变色 -->
                              <el-table :data="taskDetailsData.info" border stripe :header-cell-style="{textAlign: 'center'}"  :cell-style="{textAlign: 'center'}" >
                                   <el-table-column label="任务编号" prop="id"></el-table-column>
                                   <el-table-column label="撤销时间" prop="stime"></el-table-column>
                                   <!-- <el-table-column label="撤销单号" prop="orderid"></el-table-column> -->
                                   <el-table-column label="快递单号" prop="courier"></el-table-column>
                                   <el-table-column label="实付金额/元" prop="money"> </el-table-column>
                                   <el-table-column label="备注" prop="note"></el-table-column>
                                   <el-table-column label="订单状态" prop="statename"></el-table-column>
                              </el-table>    
                              <!-- 分页区域 -->
                              <el-pagination
                                :current-page="queryInfo.pagenum"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :page-sizes="taskDetailsData.page_arr"
                                :page-size="queryInfo.pagesize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="taskDetailsData.num"
                              ></el-pagination>
                          </div>
                    </el-tab-pane>


                    <el-tab-pane label="订单待审核" name="6">
                           <div class="external_box">
                              <Title :title="tit[0]"></Title>
                              <p class="tips">用户未按照设置本金下单的，系统将不再自动过审，需商家手动审核，请及时去审核，以免时间过长导致用户申请退款,如超过48小时未审核，转入平台客服手动审核</p>
                               <!-- 列表区域  border加边框 stripe隔行变色 -->
                              <el-table :data="taskDetailsData.info" border stripe :header-cell-style="{textAlign: 'center'}"  :cell-style="{textAlign: 'center'}" >
                                   <el-table-column label="任务编号" prop="id"></el-table-column>
                                   <el-table-column label="接单时间" prop="ctime"></el-table-column>
                                   <el-table-column label="订单编号" prop="taskbh"></el-table-column>
                                   <el-table-column label="交易平台订单" prop="orderid"></el-table-column>
                                   <el-table-column label="接单账号" prop="userid"></el-table-column>
                                   <el-table-column label="实付金额/元" prop="money"> </el-table-column>
                                   <el-table-column label="订单状态" prop="statename"></el-table-column>
                              </el-table>

                              <!-- 分页区域 -->
                              <el-pagination
                                :current-page="queryInfo.pagenum"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :page-sizes="taskDetailsData.page_arr"
                                :page-size="queryInfo.pagesize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="taskDetailsData.num"
                              ></el-pagination>
                           </div>
                    </el-tab-pane>


                    <el-tab-pane label="好评待审核" name="7">
                           <div class="external_box">
                              <Title :title="tit[0]"></Title>
                              <p class="tips">用户未按照设置本金下单的，系统将不再自动过审，需商家手动审核，请及时去审核，以免时间过长导致用户申请退款,如超过48小时未审核，转入平台客服手动审核</p>
                               <!-- 列表区域  border加边框 stripe隔行变色 -->
                              <el-table :data="taskDetailsData.info" border stripe :header-cell-style="{textAlign: 'center'}"  :cell-style="{textAlign: 'center'}" >
                                   <el-table-column label="任务编号" prop="id"></el-table-column>
                                   <el-table-column label="接单时间" prop="ctime"></el-table-column>
                                   <el-table-column label="订单编号" prop="taskbh"></el-table-column>
                                   <el-table-column label="交易平台订单" prop="orderid"></el-table-column>
                                   <el-table-column label="接单账号" prop="userid"></el-table-column>
                                   <el-table-column label="实付金额/元" prop="money"> </el-table-column>
                                   <el-table-column label="订单状态" prop="statename"></el-table-column>
                              </el-table>

                              <!-- 分页区域 -->
                              <el-pagination
                                :current-page="queryInfo.pagenum"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :page-sizes="taskDetailsData.page_arr"
                                :page-size="queryInfo.pagesize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="taskDetailsData.num"
                              ></el-pagination>
                          </div>
                    </el-tab-pane>
                  </el-tabs>

          </el-card>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return{
             tit: ["任务商品基本信息","放单计划设置", "接单用户账号属性限制","商家附加要求"],
             //面包屑数据
             breadcrumbData:[ 
                     {
                         id:1,
                         title:'好物电商',
                         path:'',
                         isClick:false,
                     },
                     {
                         id:2,
                         title:'任务管理',
                         path:'',
                         isClick:false,
                     },
                     {
                         id:3,
                         title:'垫付任务管理',
                         path:'',
                         isClick:false,
                     },
                     {
                         id:4,
                         title:'任务详情',
                         path:'taskdetails',
                         isClick:false,
                     }
             ],
             activeName: '1',
             //获取用户列表的参数对象
             queryInfo: {
               // 当前的页数
               pagenum: 1,
               // 当前每一页显示多少条数据
               pagesize: 10
             },
        }
    },
    computed:{
            ...mapState({
                taskDetailsData:state => state.advancePayment.taskDetailsData,
            })
    },
    methods: {
         handleClick() {
              const { activeName } = this
              const taskbh = this.$route.query.bh
              if(activeName==1){
                  this.tit = ["任务商品基本信息","放单计划设置", "接单用户账号属性限制","商家附加要求"]
                  this.$nextTick(async ()=>{
                       try{
                            await this.$store.dispatch('getTaskDetails',{state:activeName,taskbh})               
                       }catch(error){
                            this.$message.error(error.message)
                       }
                  })
              }else if(activeName==2){
                  this.tit = ["任务费用详情"]
                  this.$nextTick(async ()=>{
                       try{
                            await this.$store.dispatch('getTaskDetails',{state:activeName,taskbh})               
                       }catch(error){
                            this.$message.error(error.message)
                       }
                  })
              }else if(activeName==3){
                  this.tit = ["进行中订单"]
                  this.$nextTick(async ()=>{
                       try{
                            await this.$store.dispatch('getTaskDetails',{state:activeName,taskbh,num:this.queryInfo.pagesize,page:this.queryInfo.pagenum})               
                       }catch(error){
                            this.$message.error(error.message)
                       }
                  })
              }else if(activeName==4){
                  this.tit = ["已完成订单"]
                  this.$nextTick(async ()=>{
                       try{
                            await this.$store.dispatch('getTaskDetails',{state:activeName,taskbh,num:this.queryInfo.pagesize,page:this.queryInfo.pagenum})               
                       }catch(error){
                            this.$message.error(error.message)
                       }
                  })
              }else if(activeName==5){
                  this.tit = ["已驳回订单"]
                  this.$nextTick(async ()=>{
                       try{
                            await this.$store.dispatch('getTaskDetails',{state:activeName,taskbh,num:this.queryInfo.pagesize,page:this.queryInfo.pagenum})               
                       }catch(error){
                            this.$message.error(error.message)
                       }
                  })
              }else if(activeName==6){
                  this.tit = ["订单待审核"]
                 this.$nextTick(async ()=>{
                       try{
                            await this.$store.dispatch('getTaskDetails',{state:activeName,taskbh,num:this.queryInfo.pagesize,page:this.queryInfo.pagenum})               
                       }catch(error){
                            this.$message.error(error.message)
                       }
                  })
              }else{
                  this.tit = ["好评待审核"]
                  this.$nextTick(async ()=>{
                       try{
                            await this.$store.dispatch('getTaskDetails',{state:activeName,taskbh,num:this.queryInfo.pagesize,page:this.queryInfo.pagenum})               
                       }catch(error){
                            this.$message.error(error.message)
                       }
                  })
              }
        },

        //监听pagesize改变的事件
        handleSizeChange(newSize) {
          console.log(newSize)
          this.queryInfo.pagesize = newSize;
          this.queryInfo.pagenum = 1;
          this.handleClick();
        },
        
        //监听页码值改变的事件
        handleCurrentChange(newPage) {
          console.log(newPage)
          this.queryInfo.pagenum = newPage;
          this.handleClick();
        },
    },
    mounted(){
        this.handleClick()
    },
    watch:{
        activeName(newValue,oldValue){
            this.queryInfo = {pagenum:1,pagesize:10}
        }
    }
}
</script>

<style lang="less" scoped>
.speed_of_progress{
    display:flex;
    justify-content:flex-start;
    align-items: center;
    margin-top:20px;
    .item{
        margin-right:15px;
        display:flex;
        justify-content:flex-start;
        align-items: center;
        .steps{
            width:150px;
            line-height:36px;
            text-align: center;
            padding: 0 5px;
            background-color: #e6e6e6;
            color: #a2a2a2;
            font-size:15px;
            font-weight:500;
        }
        .triangle{
            width: 0;
            height: 0;
            border-top: 18px solid transparent;
            border-left: 20px solid  #e6e6e6;
            border-bottom: 18px solid transparent;
        }
    }
}
.order_details{
    margin-top:20px;
    font-size:16px;
    color:#a2a2a2;
}
.steps_active{
    background: rgba(@themeColor, 1) !important;
    color:#ffffff !important;
}
.triangle_active{
    border-left: 20px solid rgba(@themeColor, 1) !important;
}
.external_box{
    .floor{
        height:auto;
        background:#fafafa;
        border: 1px solid #e4e7ed;
        margin-top:5px;
        margin-bottom:30px;
        color:#a2a2a2;
        font-size:16px;
        .main{
             height:auto;
             margin:20px;
             .list{
                   display: flex;
                   justify-content: flex-start;
                   align-items: center;
                   border-bottom:1px solid #f8f8f8;
                   padding:10px 0 10px;
                   .item{
                        height:auto;
                        line-height:40px;
                        margin-right:100px;
                        display:flex;
                        font-size:16px;
                        .title{
                             font-size:17px;
                             font-weight:500;
                        }
                        .right{
                             margin-left:10px;
                        }
                    }     
             }
             .s_list{
                 display: flex;
                 flex-direction: column;
                 .list_item{
                     display: flex;
                     margin-bottom:2px;
                     .item{
                        height:auto;
                        line-height:40px;
                        margin-right:100px;
                        display:flex;
                        font-size:16px;
                        .title{
                             font-size:17px;
                             font-weight:500;
                        }
                        .right{
                             margin-left:10px;
                        }
                    } 
                 }
             }
             .rules_box{
                  margin-top: 20px;
                  border: 1px solid rgba(@themeColor, 1);
                  width:45%;         
                 .list{
                     padding:15px;
                     display:flex;
                    .item{
                        margin-right:20px;
                    }
                 }
                 
             }
        }
    }
    .tips{
        margin-left: 20px;
        color: #d40b35;
        font-size: 16px;
        font-weight: 500;
        margin:10px 0 0;
    }
    .money_box{
        text-align:right;
        margin-top:20px;
        .money{
            color:#FF9940;
            font-size:18px;
            font-weight:600;
        }
    }
 }
 .remarks_img_box{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
 }
.font_color{
    color: #4165f2 !important;
}
.el-card {
  margin: 20px 20px 50px 20px;
}
.el-pagination {
  margin-top:20px;
}
.el-tabs{
  margin: 20px 20px 50px 20px;
}
.el-table{
    margin-top:10px;
}
.el-pagination {
  margin-top: 20px;
}
</style>